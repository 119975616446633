<template>
  <div class="mt-5">
    <rm-allocations-table />
  </div>
</template>

<script>
import RmAllocationsTable from "./components/RmAllocationsTable.vue";

export default {
  components: {
    RmAllocationsTable,
  },
};
</script>
